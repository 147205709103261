import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import chaletApi from '../../../../../api/chaletApi';
import { closeEditProductModal } from '../../../../../redux/modalSlice';
import { ProductType } from 'types/ProductTypes';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { AddVariationForm } from './AddVariationForm';
import { VariationCard } from './VariationCard';
import { Add, AddBox } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { pictureValidation } from 'utils/pictureValidation';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type EditProductModalProps = {
  productId: number;
  resetProductId: () => void;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 15,
  width: 700,
  height: 700,
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  }
}));

const schema = yup.object().shape({
  name: yup.string().required('El nombre es requerido'),
  description: yup.string().required('La descripción es requerida'),
  price: yup
    .number()
    .required('El precio es requerido')
    .positive('El precio debe ser mayor que cero'),
  category: yup
    .number()
    .required('La categoría es requerida')
    .positive('La categoría debe ser mayor que cero')
});

export const EditProductModal: React.FC<EditProductModalProps> = ({
  productId,
  resetProductId
}) => {
  const { isEditProductModalOpen } = useAppSelector((s) => s.modalSlice);
  const { data: product, isSuccess } = chaletApi.useGetProductByIdQuery(productId);
  const [editProduct, result] = chaletApi.useEditShopProductMutation();
  const { data: categoriesData } = chaletApi.useGetProductCategoriesQuery(ProductType.Food);
  const [isAddingElection, setIsAddingElection] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      category: 0
    }
  });

  useEffect(() => {
    if (isSuccess && product) {
      setValue('name', product.name);
      setValue('description', product.description);
      setValue('price', product.price);
      setValue('category', product.categoryId);
      setPreviewUrl(product.image);
    }
  }, [product, isSuccess, setValue]);

  const handleClose = () => {
    resetProductId();
    setImage(null);
    setPreviewUrl(null);
    dispatch(closeEditProductModal());
  };

  const handleAddElection = useCallback(() => {
    setIsAddingElection(true);
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      pictureValidation(file, setImage, setPreviewUrl);
    }
  };

  const saveProduct = (data: any) => {
    if (image) {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('price', String(data.price));
      formData.append('categoryId', String(data.category));
      formData.append('image', image);

      toast.promise(
        editProduct({ productId, body: formData })
          .unwrap()
          .then(() => {
            handleClose();
          })
          .catch(() => {
            toast('Error guardando menu');
          }),
        {
          loading: 'Guardando...',
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>
        }
      );
    } else {
      const productData = {
        name: data.name,
        description: data.description,
        price: data.price,
        categoryId: data.category
      };

      toast.promise(
        editProduct({ productId, body: productData })
          .unwrap()
          .then(() => {
            handleClose();
          })
          .catch(() => {
            toast('Error guardando menu');
          }),
        {
          loading: 'Guardando...',
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>
        }
      );
    }
  };

  if (product) {
    return (
      <Modal open={isEditProductModalOpen} onClose={handleClose}>
        <BoxStyled>
          <Typography textAlign={'center'} variant="h5" fontWeight={'bold'}>
            Editar producto
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box component={'form'} onSubmit={handleSubmit(saveProduct)}>
              <Grid container>
                <Grid item md={6} p={1}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{ mt: 2 }}
                        placeholder="Nombre"
                        label="Nombre"
                        type="text"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                      />
                    )}
                  />

                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{ mt: 2 }}
                        placeholder="Descripción"
                        label="Descripción"
                        type="text"
                        multiline
                        rows={3}
                        error={!!errors.description}
                        helperText={errors.description ? errors.description.message : ''}
                      />
                    )}
                  />

                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{ mt: 2 }}
                        placeholder="Precio"
                        label="Precio"
                        type="text"
                        error={!!errors.price}
                        helperText={errors.price ? errors.price.message : ''}
                      />
                    )}
                  />

                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="category">Categoría</InputLabel>
                        <Select {...field} label="Categoría" labelId="category">
                          {categoriesData?.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  p={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <Box>
                    {previewUrl ? (
                      <Box position={'relative'} display={'flex'} justifyContent={'center'}>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#c7c7c7',
                            zIndex: 1,
                            '&:hover': {
                              backgroundColor: `rgba(128, 128, 128, 0.7)`
                            }
                          }}
                          component="label"
                          color="primary">
                          <AddBox sx={{ width: 35, height: 35, color: '#534341' }} />
                          <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                        </IconButton>
                        <Avatar
                          src={previewUrl}
                          variant="square"
                          sx={{
                            width: 300,
                            height: 300,
                            backgroundColor: '#c7c7c7',
                            borderRadius: '28px',
                            m: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                          }}
                        />
                      </Box>
                    ) : (
                      <IconButton
                        sx={{
                          width: 300,
                          height: 300,
                          backgroundColor: '#c7c7c7',
                          borderRadius: '28px',
                          m: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}
                        component="label">
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <AddBox sx={{ width: 77, height: 77, color: '#534341' }} />
                        <Typography color={'#534341'}>Selecciona una foto</Typography>
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent={'center'} mt={2} spacing={1}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="contained" type="submit">
                  Guardar Cambios
                </Button>
              </Stack>
            </Box>

            <Divider sx={{ mt: 4 }}>
              <Chip  label='Agrega variaciones a tu producto' size='medium' />
            </Divider>
                    
            {product.variations.map((v) => {
              return <VariationCard key={v.id} variation={v} />;
            })}
            <Box marginTop={2}>
              {isAddingElection ? (
                <AddVariationForm
                  productId={productId}
                  electionsQty={product.variations.length}
                  onClose={() => setIsAddingElection(false)}
                />
              ) : (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Button endIcon={<Add/>} variant="contained" color='secondary' onClick={handleAddElection}>
                    Agregar
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </BoxStyled>
      </Modal>
    );
  }
  return null;
};
