import { Avatar, Box, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import chaletApi from 'api/chaletApi';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { closeProductsListModal } from 'redux/modalSlice';

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 15,
  width: 700,
  height: 600,
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  }
}));

type props = { 
  from : Dayjs,
  to: Dayjs,
  branchId: number,
}

export const ProductsListModal = ({from, to , branchId} : props) => {
  const { isProductListModalOpen } = useAppSelector((state) => state.modalSlice);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(closeProductsListModal());
  };

  const [getProductsSolded, result] = chaletApi.useLazyGetProductsSoldQuery()

  useEffect(() => {
    if (from && to && branchId) {
      getProductsSolded({ id: branchId, from, to });
    }
  }, [from, to, branchId])

  return (
    <Modal
      open={isProductListModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <BoxStyled>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell align="right">Cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.data && result.data.data.map((products : any, index: number) => (
              <TableRow
                key={products.productId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderRadius: 100,
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Avatar src={products.product.image} />
                    <Typography fontWeight={"bold"}>{products.product.name}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{products.totalQuantitySold}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </BoxStyled>
    </Modal>
  );
};
