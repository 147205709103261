import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React from 'react';
import { BranchMenus, missingCategories } from 'types/BranchMenus';
import { toCurrencyFormat } from 'utils/formatter';

type NewsCategoriesProps = {
  missingCategories?: missingCategories[];
  currentMenu: BranchMenus;
};

export const NewsCategories = ({ missingCategories, currentMenu }: NewsCategoriesProps) => {
  const [addBranchMenuCategory] = chaletApi.useAddBranchMenuCategoryMutation();

  const addNewBranchMenuCategory = (category: missingCategories) => {
    const data = {
      ...category,
      branchMenuId: currentMenu.id
    };

    addBranchMenuCategory(data);
  };

  if (missingCategories && missingCategories.length > 0) {
    return (
      <>
        <Divider sx={{ m: 2 }}>
          <Chip color="primary" label="Nuevas Categorías" size="medium" />
        </Divider>
        <Typography textAlign={'center'} variant="subtitle2" color={'text.secondary'}>
          Son categorías que no existen actualmente en tu menú.
        </Typography>
        {missingCategories.map((category) => (
          <Accordion key={category.id} elevation={1} sx={{ mt: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header">
              <Typography fontWeight={'bold'}> {category.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={'text.secondary'}>Productos incluidos</Typography>
              {category.items.map((item) => (
                <Card
                  key={item.id}
                  sx={{
                    p: 2,
                    m: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      gap: 2
                    }}>
                    <Avatar src={item.image}></Avatar>
                    <Typography>{item.name}</Typography>
                  </Box>
                  <Typography>{toCurrencyFormat(item.price)}</Typography>
                </Card>
              ))}
            </AccordionDetails>
            <AccordionActions>
              <Button variant="contained" onClick={() => addNewBranchMenuCategory(category)}>
                Agregar
              </Button>
            </AccordionActions>
          </Accordion>
        ))}
      </>
    );
  } else {
    return null;
  }
};
