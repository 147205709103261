import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { formatDateWithTime, toCurrencyFormat } from 'utils/formatter';
import { Order } from 'types/Order';
import { OrderDetailsView } from '../OrderDetailsView';
import { ExpandMore, AccessTime } from '@mui/icons-material';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import { OrderModal } from '../OrderModal';

type OrderItemProps = {
  order: Order;
};

export const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const [inProcessOrder] = chaletApi.useInProcessOrderMutation();
  const [readyForPickupOrder] = chaletApi.useReadyForPickupOrderMutation();

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { palette } = useTheme();

  const handleAccordionToggle = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const InProcessOrderStep = () => {
    inProcessOrder({
      id: order.id
    })
      .unwrap()
      .then(() => {
        toast.success('Se ha completado el paso correctamente');
      })
      .catch(() => {
        toast.error('Ocurrió un error al completar el paso');
      });
  };

  const readyForPickupOrderStep = () => {
    readyForPickupOrder({
      id: order.id
    })
      .unwrap()
      .then(() => {
        toast.success('Se ha completado el paso correctamente');
      })
      .catch(() => {
        toast.error('Ocurrió un error al completar el paso');
      });
  };

  return order.currentOrderProcessStep.correlative === 1 ? (
    <>
      <Card
        sx={{
          margin: 1,
          display: 'inline-flex',
          position: 'relative',
          backgroundColor: 'primary.main',
          color: 'white.main',
          borderRadius: 4,
          boxShadow: 4,
          overflow: 'hidden',
          textAlign: 'center'
        }}
        onClick={handleOpen}
        elevation={4}>
        <Box
          sx={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            ml: 2,
            mr: 2,
            mt: 2,
            mb: 2,
            maxHeight: isAccordionExpanded ? 'none' : '500px'
          }}>
          <Box mb={2} mt={1}>
            <Box
              sx={{
                display: 'flex'
              }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  mb: 1
                }}>
                #{order.id}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}>
              <AccessTime fontSize="small" />
              <Typography
                sx={{
                  fontSize: '1rem',
                  mb: 1
                }}>
                Hace{' '}
                {Math.round(
                  (new Date().getTime() - new Date(order.createdAt).getTime()) / (1000 * 60)
                )}{' '}
                minutos
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: '1.5rem'
              }}>
              {order.details.reduce((acc, detail) => acc + detail.quantity, 0)} productos
            </Typography>
          </Box>
        </Box>
      </Card>
      <OrderModal order={order} handleClose={handleClose} isModalOpen={isModalOpen} />
    </>
  ) : (
    <Card
      sx={{ textAlign: 'center', margin: 1, display: 'inline-flex', position: 'relative' }}
      elevation={4}>
      <Box
        sx={{
          width: '300px',
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          ml: 2,
          mr: 2,
          mt: 2,
          overflow: 'hidden',
          maxHeight: isAccordionExpanded ? 'none' : '500px'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1
          }}>
          <Typography fontWeight={600} fontSize={'1.2rem'}>
            {order.user.name}
          </Typography>
          <Typography fontSize={'1.2rem'}> - </Typography>
          <Typography fontSize={'1.2rem'}>
            {formatDateWithTime(order.createdAt).split(' ')[1]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}>
          <Typography
            sx={{
              fontSize: '1rem',
              mb: 1
            }}>
            Hace{' '}
            {Math.round((new Date().getTime() - new Date(order.createdAt).getTime()) / (1000 * 60))}{' '}
            minutos
          </Typography>
        </Box>

        <Box mt={1} mb={2} flex={1}>
          <Accordion expanded={isAccordionExpanded} onChange={handleAccordionToggle}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography variant="subtitle2">Detalles de la orden</Typography>
            </AccordionSummary>
            <Box flex={1} ml={1} mr={1}>
              <OrderDetailsView details={order.details} />
              <Stack direction={'row'} alignItems={'center'}>
                <Typography textAlign={'left'} flex={1}>
                  Total:
                </Typography>
                <Typography>{toCurrencyFormat(parseFloat(order.productsTotal))}</Typography>
              </Stack>
            </Box>
          </Accordion>
        </Box>

        {order.currentOrderProcessStep.correlative <= 5 && (
          <Box
            mb={2}
            mt={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3
            }}>
            <Typography
              sx={{
                width: '100%',
                color: palette.primary.main,
                fontWeight: 600
              }}>
              {order.currentOrderProcessStep.name}
            </Typography>
            {
              {
                2: (
                  <Button variant="contained" fullWidth onClick={InProcessOrderStep}>
                    Siguiente
                  </Button>
                ),
                3: (
                  <Button variant="contained" fullWidth onClick={readyForPickupOrderStep}>
                    Siguiente
                  </Button>
                )
              }[order.currentOrderProcessStep.correlative]
            }
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          backgroundColor: palette.primary.main,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: '0px  7px'
        }}>
        <Typography color={palette.white.main} fontWeight={600} fontSize={'1.5rem'}>
          #{order.id}
        </Typography>
      </Box>
    </Card>
  );
};
