import React from 'react';
import { Avatar, Paper, Typography, Box, Stack, useTheme, Button } from '@mui/material';
import { amber, deepPurple, green } from '@mui/material/colors';
import { OpenInNew } from '@mui/icons-material';
import { useAppDispatch } from 'redux/hook';
import { openProductsListModal } from 'redux/modalSlice';
import { ProductsListModal } from 'ui/components/partials/Admin/ProductsListModal';
import { Dayjs } from 'dayjs';

type props = {
  products: [
    {
      name: string;
      quantity: number;
    }
  ];

  from: Dayjs;
  to: Dayjs;
  branchId: number;
};

export const BestProductsCards: React.FC<props> = ({ products, from, to, branchId }) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const color = [
    palette.primary.main,
    palette.secondary.main,
    amber[800],
    deepPurple[800],
    green[800]
  ];
  const openProductsList = () => {
    dispatch(openProductsListModal());
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 8,
          padding: '24px',
          border: '1px solid #e0e0e0',
          color: '#000',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.5rem',
            fontWeight: '600',
            marginBottom: '8px',
            textAlign: 'center'
          }}>
          Productos más vendidos
        </Typography>
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          {products.map((product, index) => (
            <Stack key={index} direction={'row'} alignItems={'center'} spacing={2} mt={1}>
              <Avatar sx={{ bgcolor: color[index] }}>{index + 1}</Avatar>
              <Box>
                {' '}
                <Typography>{product.name}</Typography>
                <Typography
                  variant="subtitle2"
                  color={'secondary'}>{`Cantidad: ${product.quantity}`}</Typography>
              </Box>
            </Stack>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', alignContent: 'end' }}>
          <Button onClick={openProductsList} endIcon={<OpenInNew />}>
            Productos más vendidos
          </Button>
        </Box>
      </Paper>
      <ProductsListModal from={from} to={to} branchId={branchId} />
    </>
  );
};
