import { Button, Card, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import chaletApi from '../../../../../api/chaletApi';
import { closeAddCategoryModal } from '../../../../../redux/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { BranchMenus } from 'types/BranchMenus';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type AddCategoryModalProps = {
  selectedMenu: BranchMenus;
  correlative: number;
};
const categorySchema = Yup.object().shape({
  name: Yup.string().required("El nombre de la categoría es requerido"),
});

export const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  selectedMenu,
  correlative
}) => {
  const { isAddCategoryModalOpen } = useAppSelector((state) => state.modalSlice);
  const [postMenuCategory] = chaletApi.useSaveBranchMenuCategoryMutation();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(categorySchema),
  });

  const handleClose = useCallback(() => {
    dispatch(closeAddCategoryModal());
  }, [dispatch]);

  const saveCategory = useCallback(
    async (data: any) => {
      const categoryData = {
        branchMenuId: selectedMenu.id,
        name: data.name,
        correlative,
      };

      await postMenuCategory(categoryData)
        .unwrap()
        .then(() => {
            toast.success("Guardado con éxito");
            handleClose();
        })
        .catch(() => {
          toast.error("Ha ocurrido un error");
        });
    },
    [selectedMenu, postMenuCategory, correlative, handleClose],
  );

  return (
    <Modal open={isAddCategoryModalOpen} onClose={handleClose}>
      <Box margin={8}>
        <Card sx={{ width: '50%', margin: '0 auto' }}>
        <Box
            margin={2}
            display="flex"
            flexDirection="column"
            component={"form"}
            onSubmit={handleSubmit(saveCategory)}
          >
            <Typography variant="h6" component="h2">
              Agregar categoría
            </Typography>
            <Box margin={1} />
            <TextField
              sx={{ mt: 2 }}
              placeholder="Nombre"
              type="text"
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register("name")}
            />
            <Box margin={1} />
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Box margin={1} />
            <Button title="Guardar" variant="contained" type="submit">
              Guardar
            </Button>
          </Box> 
        </Card>
      </Box>
    </Modal>
  );
};
