import React, { useEffect, useMemo } from 'react';
import chaletApi from 'api/chaletApi';
import { toast } from 'react-hot-toast';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hook';
import { OrderItem } from '../OrderItem';
import { Order } from 'types/Order';
import OrderStatus from 'types/OrderStatuses';
import { Pause, PlayArrow } from '@mui/icons-material';

export const InProcessOrderList = () => {
  const { selectedBranch } = useAppSelector((state) => state.user);
  const [getActiveOrders, result] = chaletApi.useLazyGetActiveOrdersQuery();

  const [temporarilyCloseBranch] = chaletApi.useTemporarilyCloseBranchMutation();
  const [reopenBranch] = chaletApi.useReopenBranchMutation();

  const OrderProcessStep = [
    { status: OrderStatus.RECEIVED, name: 'Nuevas' },
    { status: OrderStatus.CONFIRMED, name: 'Confirmadas' },
    { status: OrderStatus.PREPARING, name: 'En preparación' },
    { status: OrderStatus.READY_FOR_PICKUP, name: 'Listo para entregar / En Camino' }
  ];

  useEffect(() => {
    if (selectedBranch) {
      getActiveOrders(selectedBranch.id);
    }
  }, [selectedBranch]);

  const handleTemporarilyCloseBranch = async () => {
    if (selectedBranch) {
      await toast.promise(
        temporarilyCloseBranch({
          id: selectedBranch.id
        }).unwrap(),
        {
          loading: 'Actualizando estado...',
          success: <b>La sucursal se encuentra temporalmente cerrada</b>,
          error: <b>Ha ocurrido un error al cerrar la sucursal.</b>
        },
        {
          duration: 1000
        }
      );
    }
  };

  const handleReopenBranch = async () => {
    if (selectedBranch) {
      await toast.promise(
        reopenBranch({
          id: selectedBranch.id
        }).unwrap(),
        {
          loading: 'Guardando...',
          success: <b>La sucursal se encuentra nuevamente abierta</b>,
          error: <b>Ha ocurrido un error al abrir la sucursal.</b>
        },
        {
          duration: 1000
        }
      );
    }
  };

  if (result.isError) {
    toast('Error cargando ordenes.');
  }

  const ordersData = useMemo(() => {
    if (!result.data) {
      return null;
    }

    const orderMap: Record<string, Order[]> = {
      [OrderStatus.RECEIVED]: [],
      [OrderStatus.CONFIRMED]: [],
      [OrderStatus.PREPARING]: [],
      [OrderStatus.READY_FOR_PICKUP]: [],
      [OrderStatus.OUT_FOR_DELIVERY]: []
    };

    const sortedOrders = result.data.slice().sort((a, b) => a.id - b.id);

    sortedOrders.forEach((order) => {
      const status = order.currentOrderProcessStep.status;

      if (orderMap[status]) {
        orderMap[status].push(order);
      }
    });

    return {
      [OrderStatus.RECEIVED]: orderMap[OrderStatus.RECEIVED],
      [OrderStatus.CONFIRMED]: orderMap[OrderStatus.CONFIRMED],
      [OrderStatus.PREPARING]: orderMap[OrderStatus.PREPARING],
      [OrderStatus.READY_FOR_PICKUP]: orderMap[OrderStatus.READY_FOR_PICKUP].concat(
        orderMap[OrderStatus.OUT_FOR_DELIVERY]
      )
    };
  }, [result.data]);

  if (result.isLoading) {
    return (
      <Box>
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }

  if (ordersData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3
        }}>
        <Box
          sx={{
            display: 'block',
            flexWrap: 'wrap',
            textAlign: 'center',
            width: '100%',
            maxWidth: '800px'
          }}>
          <Typography
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: 'bold',
              color: '#333'
            }}>
            Ordenes en proceso
          </Typography>

          <Box
            sx={{
              bgcolor: 'whitesmoke',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 3,
              borderRadius: 2,
              boxShadow: '0 6px 14px rgba(0, 0, 0, 0.1)',
              gap: 3,
              transition: 'background-color 0.3s ease',
              '&:hover': {
                bgcolor: '#f0f0f0'
              }
            }}>
            <Typography
              variant="h6"
              sx={{
                mb: 0,
                fontWeight: 'bold',
                color: selectedBranch?.isTemporarilyClosed ? '#d32f2f' : '#2e7d32',
                fontSize: '1.25rem'
              }}>
              Estado: {selectedBranch?.isTemporarilyClosed ? 'Ocupado' : 'Recibiendo pedidos'}
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              {!selectedBranch?.isTemporarilyClosed && (
                <IconButton
                  onClick={handleTemporarilyCloseBranch}
                  aria-label="Pausar"
                  sx={{
                    bgcolor: '#ffebee',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      bgcolor: '#ffcdd2',
                      transform: 'scale(1.1)'
                    },
                    color: '#b71c1c'
                  }}>
                  <Pause />
                </IconButton>
              )}

              {selectedBranch?.isTemporarilyClosed && (
                <IconButton
                  onClick={handleReopenBranch}
                  aria-label="Reproducir"
                  sx={{
                    bgcolor: '#e8f5e9',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      bgcolor: '#c8e6c9',
                      transform: 'scale(1.1)'
                    },
                    color: '#2e7d32'
                  }}>
                  <PlayArrow />
                </IconButton>
              )}
            </Box>
          </Box>

          {OrderProcessStep.map((step) => {
            const orders = ordersData[step.status] || [];
            return (
              <Box
                key={step.status}
                sx={{
                  display: 'block',
                  textAlign: 'left',
                  marginBottom: 3
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    mt: 2,
                    color: '#555',
                    borderBottom: '2px solid #ccc',
                    paddingBottom: 1
                  }}>
                  {step.name} ({orders.length})
                </Typography>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr',
                      sm: '1fr 1fr',
                      md: '1fr 1fr'
                    },
                    gap: 2
                  }}>
                  {orders.map((order) => (
                    <OrderItem key={order.id} order={order} />
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
  return null;
};
