import { useState, useEffect } from 'react';
import { Stack, Box, Typography, Grid, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ArrowRightAlt } from '@mui/icons-material';
import chaletApi from 'api/chaletApi';
import { useAppSelector } from 'redux/hook';
import { ChartStatistics } from './ChartStatistics';
import { BestProductsCards } from './BestProductsCards';
import { InformationCards } from './InformationCards';
import { RatingCard } from './RatingCard';
import { useMediaQuery } from '@mui/material';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { OrdersInRange } from 'ui/components/partials/Order/OrdersInRange';

export const Dashboard = () => {
  const today = new Date();
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs(startOfMonth(today)));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs(endOfMonth(today)));
  const [dateRange, setDateRange] = useState('Mes');
  const { user } = useAppSelector((state) => state.user);
  const [getDashboard, result] = chaletApi.useLazyGetDashboardQuery();

  useEffect(() => {
    if (user && fromDate && toDate) {
      const branch = user.branchWork.branch;
      getDashboard({ id: branch.id, from: fromDate, to: toDate });
    }
  }, [user, fromDate, toDate]);

  const handleDateRangeChange = (value: string) => {
    let newFromDate, newToDate;

    switch (value) {
      case 'Hoy':
        newFromDate = startOfDay(today);
        newToDate = endOfDay(today);
        break;
      case 'Semana':
        newFromDate = startOfWeek(today, { weekStartsOn: 1 });
        newToDate = endOfWeek(today, { weekStartsOn: 1 });
        break;
      case 'Mes':
        newFromDate = startOfMonth(today);
        newToDate = endOfMonth(today);
        break;
      default:
        break;
    }
    setFromDate(dayjs(newFromDate));
    setToDate(dayjs(newToDate));
    setDateRange(value);
  };

  return (
    <Box p={2}>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={5}>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: '40px',
              textAlign: { xs: 'center', sm: 'left' }
            }}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Select
              value={dateRange}
              onChange={(e) => handleDateRangeChange(e.target.value)}
              label="Rango de fechas"
              variant="outlined"
              style={{ minWidth: '90px' }}>
              <MenuItem value="Hoy">Hoy</MenuItem>
              <MenuItem value="Semana">Esta semana</MenuItem>
              <MenuItem value="Mes">Este mes</MenuItem>
            </Select>

            <Box display={'flex'} alignItems={'center'}>
              <DatePicker
                label="Fecha inicial"
                value={fromDate}
                onChange={(newValue) => {
                  const start = dayjs(newValue).toDate();
                  setFromDate(dayjs(startOfDay(start)));
                }}
                sx={{ minWidth: '120px' }}
              />
              <ArrowRightAlt />
              <DatePicker
                label="Fecha final"
                value={toDate}
                onChange={(newValue) => {
                  const end = dayjs(newValue).toDate();
                  setToDate(dayjs(endOfDay(end)));
                }}
                sx={{ minWidth: '120px' }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {fromDate && toDate && result.data && (
        <>
          <InformationCards
            average={result.data.average}
            totalOrders={result.data.orders.length}
            uniqueClients={result.data.uniqueClients}
          />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              <ChartStatistics orders={result.data.orders} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <BestProductsCards
                products={result.data.mostSoldProducts}
                from={fromDate}
                to={toDate}
                branchId={user.branchWork.branch.id}
              />
            </Grid>
          </Grid>

          <OrdersInRange orders={result.data.orders} />
        </>
      )}
    </Box>
  );
};
