import { useEffect, useState } from 'react';
import chaletApi from '../../../../api/chaletApi';
import { useAppSelector } from 'redux/hook';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Button,
  TextField,
  styled,
  IconButton,
  useTheme,
  Divider
} from '@mui/material';
import { ScheduleTable } from 'ui/components/partials/Admin/ScheduleTable';
import { CloudUpload, DeliveryDining, LocationOn } from '@mui/icons-material';
import { CustomAvatar, imgStyle } from './style';
import { MapContainer } from 'ui/components/common/MapContainer';
import { pictureValidation } from 'utils/pictureValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const shopSchema = Yup.object().shape({
  name: Yup.string().required('El nombre del producto es requerido'),
  description: Yup.string().required('La descripción es requerida'),
  estimatedOrderTime: Yup.number()
    .typeError('El precio debe de ser un número')
    .required('El tiempo estimado de entrega es requerido')
    .positive('el numero debería ser positivo'),
  address: Yup.string().required('La dirección es requerida')
});

export const AdminShop = () => {
  const { user } = useAppSelector((state) => state.user);
  const [GetBranchById, result] = chaletApi.useLazyGetBranchByIdQuery();
  const [updateBranch] = chaletApi.useUpdateBranchMutation();

  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [previewCoverImage, setPreviewCoverImage] = useState<string | null>(null);
  const [LatLng, setLatLng] = useState<google.maps.LatLngLiteral | null>(null);

  const theme = useTheme();

  useEffect(() => {
    if (user) {
      const branch = user?.branchWork.branch;
      if (branch) {
        GetBranchById(branch.id);
      }
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(shopSchema)
  });

  const handleChangeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setProfileImage, setPreviewProfileImage);
    }
  };

  const handleChangeCoverImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setCoverImage, setPreviewCoverImage);
    }
  };

  const handleChangeLatLng = (coordinates: any) => {
    setLatLng(coordinates);
  };

  const SaveShopInformation = (data: any) => {
    const { name, description, estimatedOrderTime, address } = data;

    if (user) {
      const branchId = user?.branchWork.branch.id;
      if (profileImage || coverImage || LatLng) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('estimatedOrderTime', estimatedOrderTime);
        formData.append('address', address);

        if (profileImage) {
          formData.append('profileImage', profileImage);
        }
        if (coverImage) {
          formData.append('coverImage', coverImage);
        }
        if (LatLng) {
          formData.append('latitude', LatLng.lat.toString());
          formData.append('longitude', LatLng.lng.toString());
        }

        toast.promise(
          updateBranch({ branchId, body: formData })
            .unwrap()
            .then(() => {
              setEditMode(false);
              setCoverImage(null);
              setPreviewCoverImage(null);
              setProfileImage(null);
              setPreviewProfileImage(null);
            })
            .catch((err) => {
              toast.error(err);
            }),
          {
            loading: 'Guardando...',
            success: <b>Producto Guardado!</b>,
            error: <b>Ha ocurrido un error.</b>
          }
        );
      } else {
        toast.promise(
          updateBranch({ branchId, body: data })
            .unwrap()
            .then(() => {
              setEditMode(false);
              setCoverImage(null);
              setPreviewCoverImage(null);
              setProfileImage(null);
              setPreviewProfileImage(null);
            })
            .catch((err) => {
              toast.error(err);
            }),
          {
            loading: 'Guardando...',
            success: <b>Producto Guardado!</b>,
            error: <b>Ha ocurrido un error.</b>
          }
        );
      }
    }
  };

  if (result.isError) {
    return <Typography>Ha ocurrido un error</Typography>;
  }

  if (result.isLoading) {
    return (
      <Box sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }} padding={4}>
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  if (result.data) {
    const branch = result.data;

    return (
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Box component={'form'} onSubmit={handleSubmit(SaveShopInformation)}>
              <Box>
                <Box position="relative" display="flex" justifyContent="center">
                  {editMode && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: theme.palette.white.main,
                        '&:hover': {
                          backgroundColor: `rgba(128, 128, 128, 0.7)`
                        }
                      }}
                      component="label"
                      color="primary">
                      <CloudUpload />
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        onChange={handleChangeCoverImage}
                      />
                    </IconButton>
                  )}

                  <img
                    style={imgStyle}
                    src={previewCoverImage ? previewCoverImage : branch.coverImage}
                    alt="Cover"
                  />
                </Box>
              </Box>
              <Box>
                <Grid container>
                  <Grid item xs={8} sm={8} md={8}>
                    <Box sx={{ ml: 2, mt: 1 }}>
                      {editMode ? (
                        <>
                          <TextField
                            defaultValue={branch.name}
                            label="Nombre de la tienda"
                            fullWidth
                            sx={{ mt: 1 }}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            {...register('name')}
                          />
                          <TextField
                            defaultValue={branch.estimatedOrderTime}
                            label="Tiempo estimado de entrega"
                            fullWidth
                            sx={{ mt: 1 }}
                            error={!!errors.estimatedOrderTime}
                            helperText={errors.estimatedOrderTime?.message}
                            {...register('estimatedOrderTime')}
                          />
                        </>
                      ) : (
                        <>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {branch.name}
                          </Typography>
                          <Box display={'flex'} alignItems={'center'}>
                            <DeliveryDining sx={{ marginRight: '0.5rem' }} />
                            <Typography variant="body2">
                              {branch.estimatedOrderTime} - {branch.estimatedOrderTime + 15}
                              min
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} alignItems={'center'}>
                    <Box position={'relative'} display={'flex'} justifyContent={'center'}>
                      {editMode && (
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: { md: '0px' },
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: theme.palette.white.main,
                            zIndex: 1,
                            '&:hover': {
                              backgroundColor: `rgba(128, 128, 128, 0.7)`
                            }
                          }}
                          component="label"
                          color="primary">
                          <CloudUpload />
                          <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            onChange={handleChangeProfileImage}
                          />
                        </IconButton>
                      )}
                      <CustomAvatar
                        src={previewProfileImage ? previewProfileImage : branch.profileImage}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ pr: 2, pl: 2 }}>
                      {editMode ? (
                        <>
                          <TextField
                            defaultValue={branch.address}
                            label="Dirección de la tienda"
                            fullWidth
                            multiline
                            rows={2}
                            sx={{ mt: 1 }}
                            error={!!errors.address}
                            helperText={errors.address?.message}
                            {...register('address')}
                          />
                          <TextField
                            defaultValue={branch.description}
                            label="Descripción"
                            fullWidth
                            multiline
                            rows={3}
                            sx={{ mt: 1 }}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            {...register('description')}
                          />
                        </>
                      ) : (
                        <>
                          <Box display={'flex'} alignItems={'center'}>
                            <LocationOn sx={{ marginRight: '0.5rem' }} />
                            <Typography variant="body2">{branch.address}</Typography>
                          </Box>
                          <Typography style={{ fontSize: 16 }} maxWidth={'450px'}>
                            {branch.description}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ pt: 2 }}></Divider>

              <Box sx={{ padding: 2 }}>
                <MapContainer
                  editMode={editMode}
                  location={branch.location}
                  getCoordinates={handleChangeLatLng}
                />
              </Box>

              {!editMode && (
                <Box display={'flex'} justifyContent={'center'}>
                  <Button variant="contained" onClick={() => setEditMode(!editMode)}>
                    Editar información
                  </Button>
                </Box>
              )}

              {editMode && (
                <Box display={'flex'} justifyContent={'center'}>
                  <Button variant="contained" fullWidth type="submit">
                    Guardar
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              p: 2
            }}>
            <Typography display={'flex'} justifyContent={'center'} fontWeight={'bold'} variant="h5">
              Horarios
            </Typography>
            <ScheduleTable branch={branch} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return null;
};
