/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import chaletApi from '../api/chaletApi';
import { Branch } from 'types/Shop';
import { User } from 'types/User';

type UserState = {
  user: any | null;
  selectedBranch: Branch | null;
};

const initialState: UserState = {
  user: null,
  selectedBranch: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedBranch: (state, action: PayloadAction<Branch>) => {
      state.selectedBranch = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.login.matchFulfilled,
      (state, action: PayloadAction<{ success: boolean; data: { user: any } }>) => {
        if (action.payload.success) {
          state.user = action.payload.data.user;
        }
      }
    );
    builder.addMatcher(
      chaletApi.endpoints.getUserById.matchFulfilled,
      (state, action: PayloadAction<{ success: boolean; data: User }>) => {
        if (action.payload) {
          const user = action.payload.data
          state.user = user
          state.selectedBranch = user.branchWork.branch
        }
      }
    );
  }
});

export const { setSelectedBranch } = userSlice.actions;
export default userSlice

