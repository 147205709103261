import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React from 'react';
import { BranchMenus, missingProducts } from 'types/BranchMenus';
import { Product } from 'types/Product';
import { toCurrencyFormat } from 'utils/formatter';

type ExistingCategoriesProps = {
  missingProducts?: missingProducts;
  currentMenu: BranchMenus
};

type body = {
  categoryId: number;
  categoryName: string;
  items: Product[];
};

export const ExistingCategories = ({ missingProducts, currentMenu }: ExistingCategoriesProps) => {
  const [addBranchMenuProducts] = chaletApi.useAddBranchMenuProductMutation();

  const addNewBranchMenuProduct = (category: body) => {

    const data = { 
      ...category,
      branchMenuId : currentMenu.id
    }

    addBranchMenuProducts(data);
  };

  if (missingProducts && Object.keys(missingProducts).length > 0) {
    return (
      <>
        <Divider sx={{ m: 2 }}>
          <Chip color="secondary" label="Categorias existentes" size="medium" />
        </Divider>
        <Typography textAlign={'center'} variant="subtitle2" color={'text.secondary'}>
          Son categorías que existen en tu menú, pero hay nuevos productos que no posees.
        </Typography>
        {Object.keys(missingProducts).map((category) => {
          const id = Number(category);
          const { categoryId, categoryName, items } = missingProducts[id];
          return (
            <Accordion key={categoryId} elevation={1} sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header">
                <Typography fontWeight={'bold'}>{categoryName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color={'text.secondary'}>Productos incluidos</Typography>
                {items.map((item) => (
                  <Card
                    key={item.id}
                    sx={{
                      p: 2,
                      m: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        gap: 2
                      }}>
                      <Avatar src={item.image}></Avatar>
                      <Typography>{item.name}</Typography>
                    </Box>
                    <Typography>{toCurrencyFormat(item.price)}</Typography>
                  </Card>
                ))}
              </AccordionDetails>
              <AccordionActions>
                <Button
                  variant="contained"
                  onClick={() => addNewBranchMenuProduct({ categoryId, categoryName, items })}>
                  Agregar
                </Button>
              </AccordionActions>
            </Accordion>
          );
        })}
      </>
    );
  } else {
    return null;
  }
};
