import {
  Modal,
  styled,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { closeSyncModal } from '../../../../../redux/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { BranchMenus, missingCategories, missingProducts } from 'types/BranchMenus';
import { NewsCategories } from './NewsCategories';
import { ExistingCategories } from './ExistingCategories';

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 30,
  width: 700,
  height: 800,
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  }
}));

type SyncModalProps = {
  missingCategories?: missingCategories[];
  missingProducts?: missingProducts;
  currentMenu: BranchMenus;
};

export const SyncModal = ({ missingCategories, missingProducts, currentMenu }: SyncModalProps) => {
  const { isSyncModalOpen } = useAppSelector((state) => state.modalSlice);
  const dispatch = useAppDispatch();
  const handleClose = useCallback(() => {
    dispatch(closeSyncModal());
  }, [dispatch]);

  return (
    <Modal open={isSyncModalOpen} onClose={handleClose}>
      <BoxStyled>

        {/* {missingCategories?.length === 0 && !missingProducts} */}
        <Typography textAlign={'center'} variant="h5">
          Sincronización de menú
        </Typography>
        <Typography textAlign={'center'} variant="subtitle1" color={'text.secondary'}>
          Agrega las categorías o productos que quieres que pertenezcan al menú de tu sucursal.
        </Typography>


        <NewsCategories missingCategories={missingCategories} currentMenu={currentMenu} />


        <ExistingCategories missingProducts={missingProducts} currentMenu={currentMenu} />

      </BoxStyled>
    </Modal>
  );
};
